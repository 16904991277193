export const shipmentMasterDetailTables = {
  shipment_items: {
    title: 'Shipment Line Items',
    ajaxURL:
      '/api/delivery-item/?format=json&fields=id,delivery_id,hf_created,sender_details,description,quantity_and_weight,status',
    columns: [
      {
        title: 'Delivery ID',
        field: 'delivery_id',
        // have the column size adjust to the content and not an absolute value
        width: '20%',
      },
      {
        title: 'Created',
        field: 'hf_created',
      },
      {
        title: 'Sender',
        formatter: function (cell) {
          let rowData = cell.getRow().getData().sender_details;
          return (
            `<span class="d-block">${rowData.name}</span>` +
            `<span class="d-block text-muted">${rowData.contact}</span>`
          );
        },
      },
      {
        title: 'Description',
        formatter: function (cell) {
          let rowData = cell.getRow().getData().description;
          if (rowData) {
            return rowData;
          } else {
            return '-';
          }
        },
      },
      {
        title: 'Quantity',
        formatter: function (cell) {
          let rowData = cell.getRow().getData().quantity_and_weight;
          return rowData.quantity;
        },
      },
      {
        title: 'Weight (Kg)',
        formatter: function (cell) {
          let rowData = cell.getRow().getData().quantity_and_weight;
          return rowData.weight;
        },
      },
      { title: 'Status', field: 'status' },
    ],
  },
  shipment_history: {
    title: 'Shipment History',
    ajaxURL:
      '/api/shipment-logs/?format=json&fields=note,user_name,formated_statuses,hf_created',
    columns: [
      {
        title: 'Created',
        field: 'hf_created',
      },
      {
        title: 'Status From',
        formatter: function (cell) {
          let rowData = cell.getRow().getData().formated_statuses;
          return rowData.status_from;
        },
      },
      {
        title: 'Status To',
        formatter: function (cell) {
          let rowData = cell.getRow().getData().formated_statuses;
          return rowData.status_to;
        },
      },
      {
        title: 'User',
        field: 'user_name',
      },
      {
        title: 'Note',
        field: 'note',
      },
    ],
  },
};

export const zoneMasterDetailTables = {
  zone_partners: {
    title: 'Partners',
    ajaxURL:
      '/api/territory_partner_locations/?format=json&fields=id,partner,location_address,territory,partner_location',
    columns: [
      {
        title: 'Name',
        formatter: function (cell) {
          let rowData = cell.getRow().getData();
          return rowData.partner_name;
        },
      },
      {
        title: 'Address',
        formatter: function (cell) {
          let rowData = cell.getRow().getData();
          return rowData.location_address;
        },
      },
      {
        title: 'Territory',
        formatter: function (cell) {
          let rowData = cell.getRow().getData();
          return rowData.territory_name;
        },
      },
    ],
  },
  zone_users: {
    title: 'Staff',
    ajaxURL: '/api/territory_users/?format=json&fields=id,user,url',
    columns: [
      {
        title: 'Name',
        formatter: function (cell) {
          let rowData = cell.getRow().getData();
          return rowData.user_name;
        },
      },
      {
        title: 'Email',
        formatter: function (cell) {
          let rowData = cell.getRow().getData();
          return rowData.user_email;
        },
      },
      {
        title: 'Action',
        formatter: function (cell) {
          let rowData = cell.getRow().getData();
          return `<a
            class="ml-3 btn btn-sm btn-danger"
            style="font-size:12px;"
            href="${rowData.url}">
            <i class="fa fa-trash" aria-hidden="true"></i>
            <span class="ms-2 d-none d-md-inline">Remove Staff</span>
            `;
        },
      },
    ],
  },
  zone_routes: {
    title: 'Partners',
    ajaxURL: '/api/routes/?format=json&fields=id,route_name,description,url',
    columns: [
      {
        title: 'Name',
        formatter: function (cell) {
          let rowData = cell.getRow().getData();
          return rowData.route_name;
        },
      },
      {
        title: 'Description',
        formatter: function (cell) {
          let rowData = cell.getRow().getData();
          return rowData.description;
        },
      },
    ],
  },
};

import { masterDetailsTablesProps } from '../helpers/masterDetailsTablesProps';
import {
  businessPartnerDetailTablesProps,
  formatDate,
} from '../helpers/businessPartnerMasterDetailTables';

const dataTableData = {
  taxes: {
    title: 'Taxes',
    ajaxURL:
      '/api/taxes/?format=json&fields=id,name,description,tax_type,rate,tax_status',
    addURLs: [
      {
        name: 'Create Tax',
        url: '/taxes/create_tax',
      },
    ],
    exportURL: '/taxes/export_tax',
    columns: [
      { title: 'Name', field: 'name' },
      { title: 'Description', field: 'description' },
      { title: 'Type', field: 'tax_type' },
      { title: 'Rate', field: 'rate' },
      { title: 'Status', field: 'tax_status' },
      {
        title: 'Edit',
        formatter: function (cell) {
          return (
            '<a href="update_tax/' +
            cell.getRow().getData().id +
            '"><i class="fas fa-edit"></i></a>'
          );
        },
      },
      {
        title: 'Delete',
        sort: false,
        formatter: function (cell) {
          return (
            '<a href="delete_tax/' +
            cell.getRow().getData().id +
            '"><i class="fa fa-trash" aria-hidden="true" style="color:red;"></i></a>'
          );
        },
      },
    ],
  },
  customers: {
    title: 'Customers',
    ajaxURL:
      '/api/customers/?format=json&fields=id,partner_name,master_id,partner_type,customer_segment,primary_phone_number,primary_email_address,url',
    addURLs: [
      {
        name: 'Add Individual',
        url: '/navigation/logistics/customer/new-customer/customer/individual/',
      },
      {
        name: 'Add Corporate',
        url: '/navigation/logistics/customer/new-customer/customer/corporate/',
      },
    ],
    exportURL: '/partners/export_customers',
    columns: [
      {
        title: 'Name',
        field: 'partner_name',
        formatter: function (cell) {
          let rowData = cell.getRow().getData();
          return (
            `<span class="d-block">${rowData.partner_name}</span>` +
            `<span class="d-block text-muted">${rowData.master_id}</span>`
          );
        },
      },
      {
        title: 'Partner Type',
        field: 'partner_type',
        formatter: function (cell) {
          let rowData = cell.getRow().getData();
          let partner_type = rowData.partner_type
            .split(' ')
            .map(
              word =>
                word.charAt(0).toUpperCase() + word.slice(1).toLowerCase(),
            )
            .join(' ');
          let customer_segment = rowData.customer_segment
            .split(' ')
            .map(
              word =>
                word.charAt(0).toUpperCase() + word.slice(1).toLowerCase(),
            )
            .join(' ');
          return (
            `<span class="d-block">${partner_type}</span>` +
            `<span class="d-block text-muted">${customer_segment}</span>`
          );
        },
      },
      {
        title: 'Primary Phone',
        field: 'primary_phone_number',
      },
      {
        title: 'Primary Email',
        field: 'primary_email_address',
      },
      {
        title: 'View Details',
        field: 'url',
        formatter: function (cell) {
          return `<a href="/navigation/logistics/customer/${
            cell.getRow().getData().id
          }/" class="btn btn-sm btn-success">View</a>`;
        },
      },
    ],
  },
  suppliers: {
    title: 'Suppliers',
    ajaxURL:
      '/api/suppliers/?format=json&fields=id,partner_name,master_id,partner_type,supplier_segment,primary_phone_number,primary_email_address,url',
    addURLs: [
      {
        name: 'Add Supplier',
        url: '/partners/add/supplier',
      },
    ],
    exportURL: '/partners/export_suppliers',
    columns: [
      {
        title: 'Name',
        field: 'partner_name',
        formatter: function (cell) {
          let rowData = cell.getRow().getData();
          return (
            `<span class="d-block">${rowData.partner_name}</span>` +
            `<span class="d-block text-muted">${rowData.master_id}</span>`
          );
        },
      },
      {
        title: 'Partner Type',
        field: 'partner_type',
        formatter: function (cell) {
          let rowData = cell.getRow().getData();
          let partner_type = rowData.partner_type
            .split(' ')
            .map(
              word =>
                word.charAt(0).toUpperCase() + word.slice(1).toLowerCase(),
            )
            .join(' ');
          let supplier_segment = rowData.supplier_segment
            .split(' ')
            .map(
              word =>
                word.charAt(0).toUpperCase() + word.slice(1).toLowerCase(),
            )
            .join(' ');
          return (
            `<span class="d-block">${partner_type}</span>` +
            `<span class="d-block text-muted">${supplier_segment}</span>`
          );
        },
      },
      {
        title: 'Primary Phone',
        field: 'primary_phone_number',
      },
      {
        title: 'Primary Email',
        field: 'primary_email_address',
      },
      {
        title: 'View Details',
        field: 'url',
        formatter: function (cell) {
          return `<a href="${
            cell.getRow().getData().url
          }" class="btn btn-sm btn-success">View</a>`;
        },
      },
    ],
  },
  transporters: {
    title: 'Transporters',
    ajaxURL:
      '/api/third-party-logistics/?format=json&fields=id,partner_name,master_id,partner_type,supplier_segment,primary_phone_number,primary_email_address,url',
    addURLs: [
      {
        name: 'Add Transporters',
        url: '/partners/add/transporter',
      },
    ],
    exportURL: '/partners/export_transporter',
    columns: [
      {
        title: 'Name',
        field: 'partner_name',
        formatter: function (cell) {
          let rowData = cell.getRow().getData();
          return (
            `<span class="d-block">${rowData.partner_name}</span>` +
            `<span class="d-block text-muted">${rowData.master_id}</span>`
          );
        },
      },
      {
        title: 'Partner Type',
        field: 'partner_type',
        formatter: function (cell) {
          let rowData = cell.getRow().getData();
          let partner_type = rowData.partner_type
            .split(' ')
            .map(
              word =>
                word.charAt(0).toUpperCase() + word.slice(1).toLowerCase(),
            )
            .join(' ');
          let supplier_segment = rowData.supplier_segment
            .split(' ')
            .map(
              word =>
                word.charAt(0).toUpperCase() + word.slice(1).toLowerCase(),
            )
            .join(' ');
          return (
            `<span class="d-block">${partner_type}</span>` +
            `<span class="d-block text-muted">${supplier_segment}</span>`
          );
        },
      },
      {
        title: 'Primary Phone',
        field: 'primary_phone_number',
      },
      {
        title: 'Primary Email',
        field: 'primary_email_address',
      },
      {
        title: 'View Details',
        field: 'url',
        formatter: function (cell) {
          return `<a href="${
            cell.getRow().getData().url
          }" class="btn btn-sm btn-success">View</a>`;
        },
      },
    ],
  },
  branches: {
    title: 'Branches',
    ajaxURL: '/api/organisation-branches/?format=json&fields=id,name,location',
    addURLs: [
      {
        name: 'Create Branch',
        url: '/navigation/setup/branches/new-branch/',
      },
    ],
    exportURL: '',
    columns: [
      { title: 'Name', field: 'name' },
      {
        title: 'Address',
        field: 'location',
        formatter: function (cell) {
          let rowData = cell.getRow().getData();
          return rowData.location.address;
        },
      },
      {
        title: 'Edit',
        field: 'url',
        formatter: function (cell) {
          return `<a href="/navigation/setup/branches/update-branch/${
            cell.getRow().getData().id
          }/"><i class="fas fa-edit"></i></a>`;
        },
      },
      {
        title: 'View Branch Details',
        sort: false,
        formatter: function (cell) {
          return `<a href="/navigation/setup/branches/${
            cell.getRow().getData().id
          }/" class="btn btn-sm btn-success">View</a>`;
        },
      },
    ],
  },
  applied_discounts: {
    title: 'Discounts Applied',
    ajaxURL:
      '/api/discount-applied/?format=json&fields=id,sku_name,discount_name,amount',
    addURLs: [
      {
        name: 'Allocate Discount',
        url: '/discounts/create_applied_discount',
      },
    ],
    exportURL: '/discounts/export_sku_discount',
    columns: [
      { title: 'Stock Keeping Unit', field: 'sku_name' },
      { title: 'Discount Name', field: 'discount_name' },
      {
        title: 'Amount',
        field: 'amount',
        formatter: function (cell) {
          let amount = cell.getRow().getData().amount;
          if (amount.type === 'amount') {
            return `KES ${amount.value}`;
          } else if (amount.type === 'rate') {
            return `${amount.value} %`;
          }
        },
      },
      {
        title: 'Edit',
        formatter: function (cell) {
          return (
            '<a href="update_applied_discount/' +
            cell.getRow().getData().id +
            '"><i class="fas fa-edit"></i></a>'
          );
        },
      },
      {
        title: 'Delete',
        sort: false,
        formatter: function (cell) {
          return (
            '<a href="delete_applied_discount/' +
            cell.getRow().getData().id +
            '"><i class="fa fa-trash" aria-hidden="true" style="color:red;"></i></a>'
          );
        },
      },
    ],
  },
  discounts: {
    title: 'Discount',
    ajaxURL:
      '/api/discounts/?format=json&fields=id,name,description,amount,rate,discount_status',
    addURLs: [
      {
        name: 'Create Discount',
        url: '/discounts/create_discount',
      },
    ],
    exportURL: '/discounts/export_discount',
    columns: [
      { title: 'Name', field: 'name' },
      { title: 'Description', field: 'description' },
      {
        title: 'Amount',
        formatter: function (cell) {
          let amount = cell.getRow().getData().amount;
          if (amount) {
            return 'KES ' + amount;
          } else {
            return '-';
          }
        },
      },
      {
        title: 'Rate',
        formatter: function (cell) {
          let rate = cell.getRow().getData().rate;
          if (rate) {
            return rate + ' %';
          } else {
            return '-';
          }
        },
      },
      { title: 'Status', field: 'discount_status' },
      {
        title: 'Edit',
        formatter: function (cell) {
          return (
            '<a href="update_discount/' +
            cell.getRow().getData().id +
            '"><i class="fas fa-edit"></i></a>'
          );
        },
      },
      {
        title: 'Delete',
        sort: false,
        formatter: function (cell) {
          return (
            '<a href="delete_discount/' +
            cell.getRow().getData().id +
            '"><i class="fa fa-trash" aria-hidden="true" style="color:red;"></i></a>'
          );
        },
      },
    ],
  },
  document_setup: {
    title: 'Document Setup',
    ajaxURL:
      '/api/document-setup/?format=json&fields=id,document_type,subject,message,terms',
    addURLs: [
      {
        name: 'Create Document Setup',
        url: '/documents/create_document_setup',
      },
    ],
    exportURL: '/documents/export_document_setup',
    columns: [
      { title: 'Document Type', field: 'document_type' },
      { title: 'Subject', field: 'subject' },
      { title: 'Message', field: 'message' },
      {
        title: 'Terms',
        field: 'terms',
        formatter: function (cell) {
          if (cell.getValue()) {
            return `<i class='fa fa-check-circle text-success justify-content-center' style='font-size:1.5rem;'></i>`;
          } else {
            return `<i class='fa fa-times-circle text-danger justify-content-center' style='font-size:1.5rem;'></i>`;
          }
        },
      },
      {
        title: 'Edit',
        formatter: function (cell) {
          return (
            '<a href="update_document_setup/' +
            cell.getRow().getData().id +
            '"><i class="fas fa-edit"></i></a>'
          );
        },
      },
      {
        title: 'Delete',
        sort: false,
        formatter: function (cell) {
          return (
            '<a href="delete_document_setup/' +
            cell.getRow().getData().id +
            '"><i class="fa fa-trash" aria-hidden="true" style="color:red;"></i></a>'
          );
        },
      },
    ],
  },
  payment_terms: {
    title: 'Payment Terms',
    ajaxURL:
      '/api/payment-term/?format=json&fields=id,name,credit_days,closing_date,type,default_payment_term',
    addURLs: [
      {
        name: 'Create Credit Terms',
        url: '/payment_terms/create_credit_terms/',
      },
      {
        name: 'Create Billing Cycle',
        url: '/payment_terms/create_billing_cycle/',
      },
    ],

    exportURL: '/payment_terms/export_payment_term',
    columns: [
      { title: 'Name', field: 'name' },
      { title: 'Credit Days', field: 'credit_days' },
      {
        title: 'Closing Date',
        field: 'closing_date',
        formatter: function (cell) {
          if (cell.getValue()) {
            return cell.getValue();
          } else {
            return '-';
          }
        },
      },
      { title: 'Type', field: 'type' },
      {
        title: 'Default',
        field: 'default_payment_term',
        formatter: function (cell) {
          if (cell.getValue()) {
            return `<i class='fa fa-check-circle text-success' style='font-size:1.5rem;'></i>`;
          } else {
            return `<i class='fa fa-times-circle text-danger' style='font-size:1.5rem;'></i>`;
          }
        },
      },
      {
        title: 'Edit',
        formatter: function (cell) {
          return (
            '<a href="/payment_terms/update_payment_term/' +
            cell.getRow().getData().id +
            '"><i class="fas fa-edit"></i></a>'
          );
        },
      },
      {
        title: 'Delete',
        formatter: function (cell) {
          if (
            cell.getRow().getData().system_generated === true ||
            cell.getRow().getData().default_payment_term === true
          ) {
            return '';
          } else {
            return (
              '<a href="/payment_terms/delete_payment_term/' +
              cell.getRow().getData().id +
              '"><i class="fa fa-trash" aria-hidden="true" style="color:red;"></i></a>'
            );
          }
        },
      },
    ],
  },
  incidents: {
    title: 'Incidents',
    ajaxURL:
      '/api/incident-report/?format=json&fields=id,created_by,incident_time,title,state,status,incident_level,url',
    addURLs: [
      {
        name: 'New Incident',
        url: '/incidents/new_incident/',
      },
    ],
    exportURL: '/incidents/export_incident_report',
    columns: [
      { title: 'Reporter', field: 'created_by' },
      { title: 'Date &amp; Time', field: 'incident_time' },
      { title: 'Subject', field: 'title' },
      { title: 'State', field: 'state' },
      { title: 'Status', field: 'status' },
      { title: 'Level', field: 'incident_level' },
      {
        title: 'Action',
        formatter: function (cell) {
          return (
            '<a class="bg-success text-light rounded py-2 px-5 text-decoration-none" style="font-size:12px;" href="' +
            cell.getRow().getData().url +
            '">View</a>'
          );
        },
      },
    ],
  },
  unit_of_measure: {
    title: 'Unit Of Measure',
    ajaxURL:
      '/api/unit-of-measure/?format=json&fields=id,name,category_type,uom_type,factor',
    addURLs: [
      {
        name: 'Create Unit Of Measure',
        url: '/products/create_unit_of_measure',
      },
    ],
    exportURL: '/products/export_unit_of_measure',
    columns: [
      { title: 'Name', field: 'name' },
      { title: 'Category', field: 'category_type' },
      { title: 'Type', field: 'uom_type' },
      { title: 'Factor', field: 'factor' },
      {
        title: 'Edit',
        formatter: function (cell) {
          if (cell.getRow().getData().system_generated === true) {
            return '';
          } else {
            return (
              '<a href="update_unit_of_measure/' +
              cell.getRow().getData().id +
              '"><i class="fas fa-edit"></i></a>'
            );
          }
        },
      },
      {
        title: 'Delete',
        formatter: function (cell) {
          if (cell.getRow().getData().system_generated === true) {
            return '';
          } else {
            return (
              '<a href="delete_unit_of_measure/' +
              cell.getRow().getData().id +
              '"><i class="fa fa-trash" aria-hidden="true" style="color:red;"></i></a>'
            );
          }
        },
      },
    ],
  },
  uom_categories: {
    title: 'Unit Of Measure Categories',
    ajaxURL: '/api/uom-category/?format=json&fields=id,name,measure_type',
    addURLs: [
      {
        name: 'Create UOM Category',
        url: '/products/create_uom_category',
      },
    ],
    exportURL: '/products/export_uom_category',
    columns: [
      { title: 'Name', field: 'name' },
      { title: 'Measure Type', field: 'measure_type' },
      {
        title: 'View',
        formatter: function (cell) {
          return `<a
            class="btn btn-sm btn-success"
            href="uom_category/${cell.getRow().getData().id}/">
            View
            </a>`;
        },
      },
    ],
  },
  product_categories: {
    title: '',
    ajaxURL:
      '/api/product-category/?format=json&fields=id,name,description,category_type',
    addURLs: [
      {
        name: 'Create Product Category',
        url: '/products/create_category',
      },
    ],
    exportURL: '',
    columns: [
      { title: 'Name', field: 'name' },
      { title: 'Description', field: 'description' },
      { title: 'Category Type', field: 'category_type' },
      {
        title: 'Edit',
        formatter: function (cell) {
          if (cell.getRow().getData().system_generated === true) {
            return '';
          } else {
            return (
              '<a href="update_category/' +
              cell.getRow().getData().id +
              '"><i class="fas fa-edit"></i></a>'
            );
          }
        },
      },
      {
        title: 'Delete',
        formatter: function (cell) {
          if (cell.getRow().getData().system_generated === true) {
            return '';
          } else {
            return (
              '<a href="delete_category/' +
              cell.getRow().getData().id +
              '"><i class="fa fa-trash" aria-hidden="true" style="color:red;"></i></a>'
            );
          }
        },
      },
    ],
  },
  products: {
    title: '',
    ajaxURL:
      '/api/products/?format=json&service=False&fields=id,name,description,category_name',
    addURLs: [
      {
        name: 'Create Product',
        url: '/products/create_product',
      },
    ],
    exportURL: '',
    columns: [
      { title: 'Name', field: 'name' },
      { title: 'Description', field: 'description' },
      { title: 'Category', field: 'category_name' },
      {
        title: 'View',
        formatter: function (cell) {
          return `<a
            class="btn btn-sm btn-success"
            href="/products/products/${cell.getRow().getData().id}/">
            View
            </a>`;
        },
      },
    ],
  },
  services: {
    title: '',
    ajaxURL: '/api/skus/?format=json&service=True&fields=id,name,description',
    addURLs: [
      {
        name: 'Create Service',
        url: '/products/create_service',
      },
    ],
    exportURL: '',
    columns: [
      { title: 'Name', field: 'name' },
      { title: 'Description', field: 'description' },
      {
        title: 'Selling Price',
        field: 'selling_price',
        formatter: function (cell) {
          return 'KES ' + cell.getValue();
        },
      },
      {
        title: 'Edit',
        formatter: function (cell) {
          if (cell.getRow().getData().system_generated === true) {
            return '';
          } else {
            return (
              '<a href="update_sku/' +
              cell.getRow().getData().id +
              '"><i class="fas fa-edit"></i></a>'
            );
          }
        },
      },
      {
        title: 'Delete',
        formatter: function (cell) {
          if (cell.getRow().getData().system_generated === true) {
            return '';
          } else {
            return (
              '<a href="delete_sku/' +
              cell.getRow().getData().id +
              '"><i class="fa fa-trash" aria-hidden="true" style="color:red;"></i></a>'
            );
          }
        },
      },
    ],
  },
  skus: {
    title: 'Stock Keeping Units',
    ajaxURL:
      '/api/skus/?format=json&service=False&fields=id,name,product_name,description,can_be_sold,can_be_bought,purchasing_price,selling_price',
    addURLs: [
      {
        name: 'Create SKU',
        url: '/products/create_sku',
      },
    ],
    exportURL: '',
    columns: [
      { title: 'Name', field: 'name' },
      { title: 'Product Name', field: 'product_name' },
      { title: 'Description', field: 'description' },
      {
        title: 'Can Be Sold',
        field: 'can_be_sold',
        formatter: function (cell) {
          if (cell.getValue() === true) {
            return `<i class='fa fa-check-circle text-success' style='font-size:1.5rem;'></i>`;
          } else {
            return `<i class='fa fa-times-circle text-danger' style='font-size:1.5rem;'></i>`;
          }
        },
      },
      {
        title: 'Can be Purchased',
        field: 'can_be_bought',
        formatter: function (cell) {
          if (cell.getValue() === true) {
            return `<i class='fa fa-check-circle text-success' style='font-size:1.5rem;'></i>`;
          } else {
            return `<i class='fa fa-times-circle text-danger' style='font-size:1.5rem;'></i>`;
          }
        },
      },
      {
        title: 'Price',
        formatter: function (cell) {
          if (cell.getRow().getData().can_be_bought === true) {
            return 'KES ' + cell.getRow().getData().purchasing_price;
          } else {
            return 'KES ' + cell.getRow().getData().selling_price;
          }
        },
      },
      {
        title: 'Edit',
        formatter: function (cell) {
          if (cell.getRow().getData().system_generated === true) {
            return '';
          } else {
            return (
              '<a href="update_sku/' +
              cell.getRow().getData().id +
              '"><i class="fas fa-edit"></i></a>'
            );
          }
        },
      },
      {
        title: 'Delete',
        formatter: function (cell) {
          if (cell.getRow().getData().system_generated === true) {
            return '';
          } else {
            return (
              '<a href="delete_sku/' +
              cell.getRow().getData().id +
              '"><i class="fa fa-trash" aria-hidden="true" style="color:red;"></i></a>'
            );
          }
        },
      },
    ],
  },
  customer_kyc: {
    title: 'Customer KYC',
    ajaxURL:
      '/api/kyc-documents/?format=json&partner_vetting=True&default_settings=True&partner_type__customer=True&fields=id,name,partner_type_name,branch,',
    addURLs: [
      {
        name: 'Create Customer KYC',
        url: '/settings/kyc_documents/customer/new',
      },
    ],
    exportURL: '',
    columns: [
      { title: 'Document Name', field: 'name' },
      { title: 'Type', field: 'partner_type_name' },
      {
        title: 'Edit',
        formatter: function (cell) {
          return (
            '<a href="/settings/customer_kyc_documents/update/' +
            cell.getRow().getData().id +
            '"><i class="fas fa-edit"></i></a>'
          );
        },
      },
      {
        title: 'Delete',
        sort: false,
        formatter: function (cell) {
          return (
            '<a href="/settings/kyc_documents/delete/' +
            cell.getRow().getData().id +
            '"><i class="fa fa-trash" aria-hidden="true" style="color:red;"></i></a>'
          );
        },
      },
    ],
  },
  supplier_kyc: {
    title: 'Supplier KYC',
    ajaxURL:
      '/api/kyc-documents/?format=json&partner_vetting=True&default_settings=True&partner_type__customer=False&fields=id,name,partner_type_name,branch,',
    addURLs: [
      {
        name: 'Create Supplier KYC',
        url: '/settings/kyc_documents/supplier/new',
      },
    ],
    exportURL: '',
    columns: [
      { title: 'Document Name', field: 'name' },
      { title: 'Type', field: 'partner_type_name' },
      {
        title: 'Edit',
        formatter: function (cell) {
          return (
            '<a href="/settings/supplier_kyc_documents/update/' +
            cell.getRow().getData().id +
            '"><i class="fas fa-edit"></i></a>'
          );
        },
      },
      {
        title: 'Delete',
        sort: false,
        formatter: function (cell) {
          return (
            '<a href="/settings/kyc_documents/delete/' +
            cell.getRow().getData().id +
            '"><i class="fa fa-trash" aria-hidden="true" style="color:red;"></i></a>'
          );
        },
      },
    ],
  },
  staff_kyc: {
    title: 'Staff KYC Documents',
    ajaxURL:
      '/api/kyc-documents/?format=json&staff_vetting=True&default_settings=True&fields=id,name,staff_type_name,branch',
    addURLs: [
      {
        name: 'Create Staff KYC',
        url: '/settings/kyc_documents/staff/new',
      },
    ],
    exportURL: '',
    columns: [
      { title: 'Document Name', field: 'name' },
      { title: 'Type', field: 'staff_type_name' },
      {
        title: 'Edit',
        formatter: function (cell) {
          return (
            '<a href="/settings/staff_kyc_documents/update/' +
            cell.getRow().getData().id +
            '"><i class="fas fa-edit"></i></a>'
          );
        },
      },
      {
        title: 'Delete',
        sort: false,
        formatter: function (cell) {
          return (
            '<a href="/settings/kyc_documents/delete/' +
            cell.getRow().getData().id +
            '"><i class="fa fa-trash" aria-hidden="true" style="color:red;"></i></a>'
          );
        },
      },
    ],
  },
  territories: {
    title: 'Territories',
    ajaxURL:
      '/api/territories/?format=json&fields=id,name,description,parent,url',
    addURLs: [],
    exportURL: '',
    columns: [
      { title: 'Name', field: 'name' },
      { title: 'Description', field: 'description' },
      {
        title: 'Parent',
        formatter: function (cell) {
          if (cell.getRow().getData().parent) {
            return cell.getRow().getData().parent.name;
          } else {
            return '-';
          }
        },
      },
      {
        title: 'View',
        formatter: function (cell) {
          return `<a
            class="btn btn-sm btn-success px-3"
            href="/navigation/setup/territory/${cell.getRow().getData().id}/"
            >
            View
            </a>`;
        },
      },
    ],
  },
  roles: {
    title: 'Roles',
    ajaxURL:
      '/api/roles/?format=json&fields=id,name,description,system_generated',
    addURLs: [
      {
        name: 'Create Role',
        url: '/users/create_role',
      },
    ],
    exportURL: '',
    columns: [
      { title: 'Name', field: 'name' },
      { title: 'Description', field: 'description' },
      {
        title: 'Edit',
        sort: false,
        formatter: function (cell) {
          const rowData = cell.getRow().getData();
          if (rowData.system_generated) {
            return '';
          } else {
            return (
              '<a href="/users/update_role/' +
              rowData.id +
              '"><i class="fas fa-edit"></i></a>'
            );
          }
        },
      },
      {
        title: 'Delete',
        sort: false,
        formatter: function (cell) {
          const rowData = cell.getRow().getData();
          if (rowData.system_generated) {
            return '';
          } else {
            return (
              '<a href="/users/role_delete/' +
              rowData.id +
              '"><i class="fa fa-trash" aria-hidden="true" style="color:red;"></i></a>'
            );
          }
        },
      },
    ],
  },
  staff: {
    title: 'Staff',
    ajaxURL:
      '/api/users/?format=json&fields=id,name,phone,email,user_type,source_display,detail_url',
    addURLs: [
      {
        name: 'Create Staff',
        url: '/navigation/logistics/user/new-user/',
      },
    ],
    exportURL: '/users/export',
    columns: [
      { title: 'Name', field: 'name' },
      { title: 'Phone', field: 'phone' },
      { title: 'Email', field: 'email' },
      {
        title: 'Type',
        formatter: function (cell) {
          return cell.getRow().getData().user_type.name;
        },
      },
      { title: 'Source', field: 'source_display' },
      {
        title: 'View',
        formatter: function (cell) {
          return `<a
            class="btn btn-sm btn-success px-3"
            href="/navigation/logistics/users/${cell.getRow().getData().id}/"
            >
            View
            </a>`;
        },
      },
    ],
  },
  user_types: {
    title: 'Staff Types',
    ajaxURL:
      '/api/user-types/?format=json&fields=id,name,requires_float_account,requires_commission_account,system_generated',
    addURLs: [
      {
        name: 'Create Staff Type',
        url: '/settings/staff_type/new',
      },
    ],
    exportURL: '',
    columns: [
      { title: 'Name', field: 'name' },
      {
        title: 'Requires Float Account',
        field: 'requires_float_account',
        formatter: function (cell) {
          if (cell.getValue() === true) {
            return `<i class='fa fa-check-circle text-success' style='font-size:1.5rem;'></i>`;
          } else {
            return `<i class='fa fa-times-circle text-danger' style='font-size:1.5rem;'></i>`;
          }
        },
      },
      {
        title: 'Requires Commission Account',
        field: 'requires_commission_account',
        formatter: function (cell) {
          if (cell.getValue() === true) {
            return `<i class='fa fa-check-circle text-success' style='font-size:1.5rem;'></i>`;
          } else {
            return `<i class='fa fa-times-circle text-danger' style='font-size:1.5rem;'></i>`;
          }
        },
      },
      {
        title: 'Edit',
        sort: false,
        formatter: function (cell) {
          const rowData = cell.getRow().getData();
          return (
            '<a href="/settings/staff_type/update/' +
            rowData.id +
            '"><i class="fas fa-edit"></i></a>'
          );
        },
      },
      {
        title: 'Delete',
        sort: false,
        formatter: function (cell) {
          const rowData = cell.getRow().getData();
          if (rowData.system_generated) {
            return '';
          } else {
            return (
              '<a href="/settings/staff_type/delete/' +
              rowData.id +
              '"><i class="fa fa-trash" aria-hidden="true" style="color:red;"></i></a>'
            );
          }
        },
      },
    ],
  },
  globalPartners: {
    title: 'Global Partners',
    ajaxURL:
      '/api/global-partner/?format=json&fields=id,name,partner_type,master_id',
    addURLs: [
      {
        name: '',
        url: '',
      },
    ],
    exportURL: '',
    columns: [
      { title: 'Name', field: 'name' },
      { title: 'Master ID', field: 'master_id' },
      {
        title: 'Type',
        field: 'partner_type',
        formatter: function (cell) {
          let rowData = cell.getRow().getData();
          let partner_type = rowData.partner_type
            .split(' ')
            .map(
              word =>
                word.charAt(0).toUpperCase() + word.slice(1).toLowerCase(),
            )
            .join(' ');
          return partner_type;
        },
      },
      {
        title: 'Edit',
        sort: false,
        formatter: function (cell) {
          const rowData = cell.getRow().getData();
          return (
            '<a href="/partners/edit-global-partner/' +
            rowData.id +
            '/' +
            '"><i class="fas fa-edit"></i></a>'
          );
        },
      },
    ],
  },
  eventLogs: {
    title: 'Event Logs',
    ajaxURL:
      '/api/event-logs/?format=json&fields=id,timestamp,message_id,name,routing_key,status,organisation_name',
    addURLs: [],
    exportURL: '',
    columns: [
      {
        title: 'Time',
        formatter: function (cell) {
          let rowData = cell.getRow().getData().timestamp;
          let formattedDate = formatDate(rowData);
          return `<span class="d-block">${formattedDate}</span>`;
        },
      },
      { title: 'Message ID', field: 'message_id' },
      { title: 'Organisation', field: 'organisation_name' },
      { title: 'Name', field: 'name' },
      { title: 'Routing Key', field: 'routing_key' },
      { title: 'Status', field: 'status' },
    ],
  },
  eventErrorLogs: {
    title: 'Event Error Logs',
    ajaxURL:
      '/api/event-error-logs/?format=json&fields=id,timestamp,event_message_id,event_name,event_routing_key,event_status,exception,organisation_name',
    addURLs: [],
    exportURL: '',
    columns: [
      {
        title: 'Time',
        formatter: function (cell) {
          let rowData = cell.getRow().getData().timestamp;
          let formattedDate = formatDate(rowData);
          return `<span class="d-block">${formattedDate}</span>`;
        },
      },
      { title: 'Event Message ID', field: 'event_message_id' },
      { title: 'Organisation', field: 'organisation_name' },
      { title: 'Event Name', field: 'event_name' },
      { title: 'Event Routing Key', field: 'event_routing_key' },
      { title: 'Event Status', field: 'event_status' },
      {
        title: 'Exception',
        field: 'exception',
        formatter: function (cell) {
          return `<span style="white-space: pre-wrap;">${cell.getValue()}</span>`;
        },
      },
    ],
  },
  customerSegments: {
    title: 'Customer Segments',
    ajaxURL:
      '/api/business-partner-segment-choices/?format=json&customer=true&fields=id,formatted_name,system_generated',
    addURLs: [
      {
        name: 'Create Partner Segment',
        url: '/partners/create-segment-choice/',
      },
    ],
    exportURL: '',
    columns: [
      { title: 'Name', field: 'formatted_name' },
      {
        title: 'Edit',
        sort: false,
        formatter: function (cell) {
          const rowData = cell.getRow().getData();
          if (rowData.system_generated) {
            return '';
          } else {
            return (
              '<a href="/partners/update-segment-choice/' +
              rowData.id +
              '/' +
              '"><i class="fas fa-edit"></i></a>'
            );
          }
        },
      },
      {
        title: 'Delete',
        sort: false,
        formatter: function (cell) {
          const rowData = cell.getRow().getData();
          if (rowData.system_generated) {
            return '';
          } else {
            return (
              '<a href="/partners/delete-segment-choice/' +
              rowData.id +
              '/' +
              '"><i class="fa fa-trash" aria-hidden="true" style="color:red;"></i></a>'
            );
          }
        },
      },
    ],
  },
  supplierSegments: {
    title: 'Supplier Segments',
    ajaxURL:
      '/api/business-partner-segment-choices/?format=json&customer=false&fields=id,formatted_name,system_generated',
    addURLs: [
      {
        name: 'Create Partner Segment',
        url: '/partners/create-segment-choice/',
      },
    ],
    exportURL: '',
    columns: [
      { title: 'Name', field: 'formatted_name' },
      {
        title: 'Edit',
        sort: false,
        formatter: function (cell) {
          const rowData = cell.getRow().getData();
          if (rowData.system_generated) {
            return '';
          } else {
            return (
              '<a href="/partners/update-segment-choice/' +
              rowData.id +
              '/' +
              '"><i class="fas fa-edit"></i></a>'
            );
          }
        },
      },
      {
        title: 'Delete',
        sort: false,
        formatter: function (cell) {
          const rowData = cell.getRow().getData();
          if (rowData.system_generated) {
            return '';
          } else {
            return (
              '<a href="/partners/delete-segment-choice/' +
              rowData.id +
              '/' +
              '"><i class="fa fa-trash" aria-hidden="true" style="color:red;"></i></a>'
            );
          }
        },
      },
    ],
  },
};

export default dataTableData;

/**
 * Holds the data  properties for a given line item table.
 */
export const lineItemsTableProps = {
  ...masterDetailsTablesProps,
  ...businessPartnerDetailTablesProps,
};

/**
 * Holds the data properties for a given master details table.
 */

/**
 * Creates a new DataTable instance using Tabulator library.
 *
 * @param {string} tableIdName - The ID or class name of the HTML element where the table will be rendered.
 * @param {string} ajaxURL - The URL to fetch the table data from.
 * @param {object} ajaxParams - The parameters to be sent along with the AJAX request.
 * @param {array} columns - The array of column definitions for the table.
 * @returns {object} - The Tabulator DataTable instance.
 */
import { TabulatorFull as Tabulator } from 'tabulator-tables';

const dataTable = (
  tableIdName,
  ajaxURL,
  ajaxParams,
  columns,
  selectable = false,
) => {
  let table = new Tabulator(tableIdName, {
    // height: '600px',
    selectableRows: selectable ? true : false,
    selectableRowsPersistence: selectable ? false : null,
    rowHeader: selectable
      ? {
          width: '1px',
          headerSort: false,
          resizable: false,
          frozen: true,
          headerHozAlign: 'center',
          hozAlign: 'center',
          formatter: 'rowSelection',
          titleFormatter: 'rowSelection',
        }
      : null,
    columns: columns,
    ajaxURL: ajaxURL,
    ajaxParams: ajaxParams,
    pagination: true,
    paginationMode: 'remote',
    filterMode: 'remote',
    ajaxConfig: 'GET',
    paginationSize: 10,
    movableColumns: true,
    paginationCounter: 'rows',
    // paginationSizeSelector: [10],
    maxHeight: '100%',
    layoutColumnsOnNewData: true,
    layout: 'fitColumns',
    responsiveLayout: true,
    dataReceiveParams: {
      last_row: 'count',
      data: 'results',
    },
    dataSendParams: {
      size: 'page_size',
    },
    placeholder: 'No Data Available',
    ajaxResponse: function (url, params, response) {
      let page_size = params.page_size;
      let count = response.count;
      response.last_page = Math.ceil(count / page_size);
      return response;
    },
  });
  return table;
};
export default dataTable;
